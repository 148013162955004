<template>
  <div class="space-y-4">
    <article v-if="createInjuryIncidents || viewInjuryIncidents">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button v-if="createInjuryIncidents"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Use First Aid Items',
            })
          "
          text="Report Injury Incident"
        >
        </asom-button>
        <asom-button v-if="viewInjuryIncidents"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Injury Incidents',
            })
          "
          text="View Injury Incidents"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-alert
      v-if="submissionError"
      variant="error"
      :error-message="submissionError"
    />
    <h3 class="subheader">
      {{ $t("screens.inventory.Main.overview.title") }}
    </h3>
    <br />
    <div class="sm:w-1/2 pb-8 flex" v-if="canVerifyFirstAidBox">
      <asom-form-field :label="'First Aid Box'">
        <asom-input-select
          v-model="filteredFirstAidBox"
          :loading="isLoadingFirstAidBoxes"
          :options="firstAidBoxOptions"
          @search-change="queryFirstAidBoxes"
          placeholder="Type to search for First Aid Box"
        />
      </asom-form-field>
      <div class="flex flex-wrap content-end pl-4">
        <asom-button size="sm" outline text="Verify" @click="verifyClicked" />
      </div>
    </div>
    <asom-card title="First Aid Inventory">
      <template #header>
        <div class="grid sm:grid-cols-1 md:grid-cols-2">
          <div class="flex flex-wrap content-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              First Aid Inventory
            </h3>
          </div>
          <div class="flex flex-row-reverse">
            <asom-export-to-excel
              fileName="First Aid Inventory Items"
              :fields="columns.fields"
              :labels="columns.labels"
              :data="columns.data"
            />
          </div>
        </div>
      </template>
      <div class="grid md:grid-cols-2 lg:gap-x-8 md:gap-x-6 gap-4">
        <asom-form-field label="Inventory Type Name">
          <asom-input-text v-model="filters.inventoryTypeName" />
        </asom-form-field>
        <asom-form-field label="Location Name">
          <asom-input-text v-model="filters.locationName" />
        </asom-form-field>
        <asom-form-field label="Expiry Date">
          <asom-input-date-range
            v-model="filters.dateRange"
            datepicker-placement="bottom"
          />
        </asom-form-field>
        <asom-form-field :label="'First Aid Box'">
        <asom-multi-select
          v-model="filters.firstAidBoxList"
          :loading="isLoadingFirstAidBoxes"
          :options="firstAidBoxOptions"
          @search-change="queryFirstAidBoxes"
          placeholder="Type to search for First Aid Box"
          :object-model-value="false"
        />
      </asom-form-field>
      </div>
      <template #footer>
        <div class="flex items-end space-x-2">
          <asom-q-r-code-scanner @detect="qrCodeResult">
          </asom-q-r-code-scanner>
          <asom-button
            text="Reset Filters"
            variant="secondary"
            @click="resetFilters"
          />
          <asom-button text="Apply" @click="getPageData(true)" />
        </div>
      </template>
    </asom-card>
    <div class="pt-8">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'inventoryTypeName',
          'firstAidBoxName',
          'itemDescription',
          'amount',
          'primaryLocationName',
          'secondaryLocationName',
          'expiryDate',
          'remarks',
          canUpdateDigitalRecords && 'action',
        ]"
        :data="itemList"
        :filterable="false"
        :sortableColumns="[
          'inventoryTypeName',
          'firstAidBoxName',
          'itemDescription',
          'amount',
          'primaryLocationName',
          'secondaryLocationName',
          'expiryDate',
        ]"
      >
        <template v-slot:primaryLocationName="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
        </template>
        <template v-slot:secondaryLocationName="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
        </template>
        <template v-slot:header_inventoryTypeName>Item Type</template>
        <template v-slot:header_amount>Quantity</template>
        <template v-slot:header_firstAidBoxName>Box</template>
        <template v-slot:header_itemDescription>Description</template>
        <template v-slot:header_primaryLocationName>Primary Location</template>
        <template v-slot:header_secondaryLocationName
          >Secondary Location</template
        >
        <template v-slot:expiryDate="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
        <template v-slot:remarks="scopedSlots">
          <span v-if="scopedSlots.data"
            ><remarks-tooltip :text="scopedSlots.data"/></span
          ><span v-else>{{ "NA" }}</span>
        </template>
        <template v-slot:action="scopedSlots">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              size="sm"
              outline
              text="QR"
              @click="viewQR(scopedSlots.rowData)"
            />
            <asom-button
              v-if="canUpdateDigitalRecords"
              size="sm"
              outline
              text="Update"
              @click="updateItem(scopedSlots.rowData)"
            />
            <asom-button
              size="sm"
              variant="success"
              outline
              text="Use"
              @click="useItemClicked(scopedSlots.rowData)"
            />
          </div>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
    <div class="pt-6">
      <asom-card title="Activity Log">
        <asom-client-table
          v-if="!isLoading"
          :columns="[
            'transactionNo',
            'officerName',
            'date',
            'time',
            'inventoryTypeName',
            'supplyDate',
            'expiryDate',
            'primaryLocationName',
            'secondaryLocationName',
            'transactionType',
            'amount',
            'remarks',
          ]"
          :data="activityList"
          :filterable="false"
        >
          <template v-slot:transactionNo="scopedSlots">
            <button
              v-if="showViewOption(scopedSlots.rowData)"
              @click="navigateToView(scopedSlots.rowData)"
              class="text-button underline font-semibold"
            >
              {{ scopedSlots.data }}
            </button>
            <span v-else>{{ scopedSlots.data }}</span>
          </template>
          <template v-slot:header_inventoryTypeName>Item Type</template>
          <template v-slot:header_amount>Quantity</template>
          <template v-slot:header_primaryLocationName
            >Primary Location</template
          >
          <template v-slot:header_secondaryLocationName
            >Secondary Location</template
          >
          <template v-slot:date="scopedSlots">
            <span>{{
              displayUtcDate(get(scopedSlots.rowData, "transactionDate"))
            }}</span>
          </template>
          <template v-slot:time="scopedSlots">
            <span>{{
              displayUtcTime(get(scopedSlots.rowData, "transactionDate"))
            }}</span>
          </template>
          <template v-slot:expiryDate="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
          <template v-slot:supplyDate="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
          <template v-slot:amount="scopedSlots">
            <span>{{
              activityLogTranasctionAmount(
                get(scopedSlots.rowData, "amount"),
                get(scopedSlots.rowData, "transactionTypeEnum")
              )
            }}</span>
          </template>
          <template v-slot:inventoryTypeName="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:primaryLocationName="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:secondaryLocationName="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:remarks="scopedSlots">
            <span v-if="scopedSlots.data"
              ><remarks-tooltip :text="scopedSlots.data"/></span
            ><span v-else>{{ "NA" }}</span>
          </template>
        </asom-client-table>
        <div v-else class="text-center">
          <asom-icon icon="spinner" class="animate-spin" />
        </div>
      </asom-card>
    </div>
    <use-item-modal
      v-model:showModal="showUseItemModal"
      :inventoryId="selectedItemId"
      :inventoryName="selectedItemName"
      @success="getPageData"
      @fail="$scrollTop"
      :inventoryGroup="4"
    />
    <update-item-modal
      v-model:showModal="showUpdateModal"
      :inventory="selectedItem"
      @success="getPageData"
      @fail="$scrollTop"
      :inventoryGroup="4"
    />
    <view-q-r-modal
      v-model:showModal="showQRCode"
      :qrCodeString="get(selectedItemForQR, 'qrCodeString', '')"
      :selectedItemName="get(selectedItemForQR, 'inventoryTypeName', 'QR Code')"
    />
    <asom-modal
      title="Verify First Aid Box"
      v-model="showVerifyModal"
      :dismissible="false"
    >
      <asom-alert
        v-if="verifyError"
        variant="error"
        :error-message="verifyError"
      />
      <p class="pt-4">
        Please confirm that all items in the selected first aid box is aligned
      </p>
      <asom-form-field
        required
        label="Remarks"
        :state="inputStates('verifyFormData.remarks')"
        error="Remarks must be more than 10 characters"
      >
        <asom-input-textarea
          type="text"
          v-model="verifyFormData.remarks"
          :state="inputStates('verifyFormData.remarks')"
          :maxlength="1000"
        />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-6">
        <div class="pl-4">
          <asom-button
            @click="onVerify"
            text="OK"
            :loading="isSubmittingVerify"
          />
        </div>
        <div>
          <asom-button
            @click="toggleVerifyModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import filter from "lodash.filter";
import { mapGetters, mapState } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import {
  displayUtcDate,
  displayUtcTime,
  parseDateTime,
} from "@/helpers/dateTimeHelpers";
import {
  getListOfFirstAidBoxes,
  verifyFirstAidBox,
} from "../../../../services/inventory.service";
import { InventoryTransactionTypes } from "../../../../constants/APIEnums/inventoryEnums";
import { activityLogTranasctionAmount } from "../../../../helpers/inventoryActivityHelper";
import UseItemModal from "../UseItemModal.vue";
import UpdateItemModal from "../UpdateItemModal.vue";
import RemarksTooltip from "../../../_RemarksTooltip.vue";
import ViewQRModal from "../ViewQRModal.vue";
import { getInventoryTypeDetails } from "../../../../services/inventory.service";
import { InventoryGroups } from "../../../../constants/APIEnums/inventoryEnums";
import { checkScanResults } from "../inventoryQRScannerMixin";
import { setStorage, getStorage } from "@/helpers/sessionStorage";
import moment from "moment";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "FirstAidMainPage",
  mixins: [inputStates, checkScanResults],
  components: {
    UseItemModal,
    UpdateItemModal,
    RemarksTooltip,
    ViewQRModal,
  },
  data() {
    return {
      filters: {
        inventoryTypeName: get(this.$route.params, "inventoryTypeName", null),
        locationName: null,
        dateRange: {
          start: null,
          end: null,
        },
        firstAidBoxList: [],
      },
      inventoryTransactionTypes: InventoryTransactionTypes,
      tableData: [],
      selectedItem: null,
      showUseItemModal: false,
      filteredFirstAidBox: null,
      firstAidBoxOptions: [],
      isLoadingFirstAidBoxes: false,
      firstAidBoxList: [],
      verifyError: null,
      showVerifyModal: false,
      isSubmittingVerify: false,
      verifyFormData: {
        remarks: null,
      },
      showUpdateModal: false,
      isLoadingFirstAidBoxList: false,
      submissionError: null,
      showQRCode: false,
      selectedItemForQR: null,
      itemList: [],
    };
  },
  watch: {
    filteredFirstAidBox(firstAidBox) {
      let firstAidBoxId = get(firstAidBox, "value");
      if (firstAidBoxId == null) {
        this.tableData = this.itemList;
      } else {
        this.tableData = filter(this.itemList, {
          firstAidBoxId: firstAidBoxId,
        });
      }
    },
    stationId() {
      this.getPageData();
    },
    '_itemList': function(){
      this.itemList = this._itemList;
    }
  },
  computed: {
    ...mapState({
      _itemList: (state) => state.inventoryManagement.inventory.firstAidItems,
      activityList: (state) =>
        state.inventoryManagement.inventory.firstAidActivityLog,
      isLoading: (state) => state.inventoryManagement.inventory.isLoading,
      error: (state) => state.inventoryManagement.inventory.error,
    }),
    ...mapGetters({
      stationId: "selectedStation/id",
      createInjuryIncidents: "auth/createInjuryIncidents",
      viewInjuryIncidents: "auth/viewInjuryIncidents",
      canUpdateDigitalRecords: "auth/canUpdateDigitalRecords",
      canVerifyFirstAidBox: "auth/canVerifyFirstAidBox",
      isOnline: "apiStatus/isInventoryModuleOnline",
      lineId: "auth/userLineId",
    }),
    columns() {
      const fields = [
        "inventoryTypeName",
        "firstAidBoxName",
        "itemDescription",
        "amount",
        "primaryLocationName",
        "secondaryLocationName",
        "expiryDate",
      ];
      const labels = [
        "Item Type",
        "First Aid Box Name",
        "Item Description",
        "Quantity",
        "Primary Location Name",
        "Secondary Location Name",
        "Expiry Date",
      ];
      const data = [];
      this.tableData.forEach((element, i) => {
        data[i] = fields.reduce((accm, field) => {
          let d = element[field];
          if (field == "expiryDate") {
            d = displayUtcDate(d);
          }
          accm[field] = d;
          return accm;
        }, {});
      });
      return {
        fields,
        labels,
        data,
      };
    },
    selectedItemId() {
      return get(this.selectedItem, "inventoryId");
    },
    selectedItemName() {
      return get(this.selectedItem, "inventoryTypeName");
    },
    queryParams() {
      let params = {
        inventoryTypeName: this.filters.inventoryTypeName,
        locationName: this.filters.locationName,
        lineId: this.lineId,
        firstAidBoxList: this.filters.firstAidBoxList,
      };
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = parseDateTime(this.filters.dateRange.start);
        params["searchDateTo"] = parseDateTime(this.filters.dateRange.end);
      }
      return params;
    },
    submissionData() {
      return {
        boxName: get(this.filteredFirstAidBox, "label"),
        firstAidBoxId: get(this.filteredFirstAidBox, "value"),
        remarks: this.verifyFormData.remarks,
      };
    },
  },
  validations() {
    return {
      verifyFormData: {
        remarks: {
          required,
          maxLength: maxLength(1000),
          minLength: minLength(10),
        },
      },
    };
  },
  mounted() {
    if (this.stationId !== null) {
      const searchVal = getStorage("first-aid");
      if(searchVal) {
        // UTC时间转换成对应的本地时间
        if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
        if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
        this.filters = searchVal;
      }
      this.$nextTick(() => {
        this.getPageData();
      })
    }
    this.getBoxList();
    this.queryFirstAidBoxes("", true);
    this.itemList = this._itemList;
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcTime,
    activityLogTranasctionAmount,
    resetFilters() {
      this.filters.dateRange = null;
      this.filters.inventoryTypeName = null;
      this.filters.locationName = null;
    },
    qrCodeResult(data) {
      let itemId = data;
      this.getTypeDetails(itemId);
    },
    async getTypeDetails(itemId) {
      const result = await getInventoryTypeDetails({
        inventoryTypeId: itemId,
        stationId: this.stationId,
      });
      if (result.success) {
        let itemDetails = get(result.payload, "inventoryTypeDetailsViewModel");
        this.filters.inventoryTypeName = this.checkPage(
          itemDetails,
          InventoryGroups.FIRST_AID.VALUE
        );
      } else {
        this.submissionError = result.payload;
        this.$scrollTop();
        return;
      }
    },
    showViewOption(data) {
      if (
        get(data, "transactionTypeEnum", null) ==
          this.inventoryTransactionTypes.VERIFY_FIRST_AID.VALUE ||
        get(data, "transactionTypeEnum", null) ==
          this.inventoryTransactionTypes.FIRST_AID_LOGBOOK.VALUE
      )
        return true;
      else return false;
    },
    navigateToView(selectedItem) {
      if (
        get(selectedItem, "transactionTypeEnum") ==
        this.inventoryTransactionTypes.VERIFY_FIRST_AID.VALUE
      ) {
        this.$router.push({
          name: "View First Aid Box",
          params: selectedItem,
        });
      } else if (
        get(selectedItem, "transactionTypeEnum") ==
          this.inventoryTransactionTypes.FIRST_AID_LOGBOOK.VALUE &&
        this.viewInjuryIncidents
      ) {
        this.$router.push({
          name: "View First Aid Log",
          params: selectedItem,
        });
      }
    },
    async getBoxList() {
      this.submissionError = null;
      this.isLoadingFirstAidBoxList = true;
      const resp = await getListOfFirstAidBoxes({
        skip: 0,
        take: 100,
        stationId: this.stationId,
        lineId: this.lineId,
      });
      if (resp.success) {
        this.firstAidBoxList = get(resp.payload, "list", []);
        this.isLoadingFirstAidBoxList = false;
        this.submissionError = null;
      } else {
        this.isLoadingFirstAidBoxList = false;
        this.submissionError = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async queryFirstAidBoxes(query, allowEmpty = false) {
      this.submissionError = null;
      this.isLoadingFirstAidBoxes = true;
      if (!query && !allowEmpty) {
        this.isLoadingFirstAidBoxes = false;
        this.firstAidBoxOptions = [];
      }
      const result = await getListOfFirstAidBoxes({
        lineId: this.lineId,
        stationId: this.stationId,
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingFirstAidBoxes = false;
        this.submissionError = null;
        this.firstAidBoxOptions = get(result, "payload.list", []).map(
          ({ inventoryFirstAidBoxId, name }) => ({
            label: name,
            value: inventoryFirstAidBoxId,
          })
        );
      } else {
        this.isLoadingFirstAidBoxes = false;
        this.submissionError = result.payload;
        this.firstAidBoxOptions = [];
      }
    },
    async getPageData(status) {
      if(status) setStorage("first-aid", this.filters);
      const resp = await this.$store.dispatch(
        "inventoryManagement/inventory/loadFirstAidItems",
        this.queryParams
      );
      if (!resp.success) {
        this.$scrollTop();
      }
    },
    useItemClicked(item) {
      this.selectedItem = item;
      this.showUseItemModal = true;
    },
    verifyClicked() {
      this.toggleVerifyModal(true);
    },
    toggleVerifyModal(value) {
      this.verifyError = null;
      this.showVerifyModal = value;
    },
    onVerify() {
      if (this.isSubmittingVerify) return;
      this.verifyError = null;
      this.v$.verifyFormData.$reset();
      this.v$.verifyFormData.$touch();
      if (!this.v$.verifyFormData.$invalid) {
        if (this.isOnline) {
          this.verifyBox();
        } else {
          this.$store.dispatch(
            "offlineData/verifyFirstAidBox",
            this.submissionData
          );
          this.toggleVerifyModal(false);
        }
      } else {
        this.verifyError = "Please complete all required fields";
      }
    },
    async verifyBox() {
      this.isSubmittingVerify = true;
      const result = await verifyFirstAidBox(this.submissionData);
      if (result.success) {
        this.isSubmittingVerify = false;
        this.toggleVerifyModal(false);
        this.$router.push({
          name: "View First Aid Box",
          params: {
            firstAidBoxId: get(this.filteredFirstAidBox, "value"),
          },
          query: { screen: JSON.stringify(this.filters) }
        });
      } else {
        this.isSubmittingVerify = false;
        this.verifyError = result.payload;
        this.$scrollTop();
      }
    },
    updateItem(item) {
      this.selectedItem = item;
      this.showUpdateModal = true;
    },
    viewQR(item) {
      this.selectedItemForQR = item;
      this.showQRCode = true;
    },
  },
};
</script>
